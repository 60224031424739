import { API_URL } from "../../../vars";
import { Interweave } from "interweave";
import Spinner from "../../general/Spinner";
import TertiaryHeading from "../../typography/TertiaryHeading";
import useFetch from "../../../hooks/useFetch";

interface ProductCategoryCardProps {
    categoryName: string;
    categoryUrl: string;
    imageId: number;
}

const ProductCategoryCard: React.FunctionComponent<
    ProductCategoryCardProps
> = ({ categoryName, categoryUrl, imageId }) => {
    const image = useFetch<any>(`${API_URL}/media/${imageId.toString()}`);

    return (
        <article className="w-1/2 mb-10 lg:mt-auto lg:w-1/4">
            {image.data ? (
                <a
                    href={categoryUrl}
                    className="block p-5 mx-2 transition-all duration-300 bg-white shadow-lg hover:shadow-xl"
                >
                    <img
                        src={image.data.source_url}
                        alt={categoryName}
                        className="h-32 mb-2"
                    />

                    <TertiaryHeading>
                        {<Interweave content={categoryName}></Interweave>}
                    </TertiaryHeading>
                </a>
            ) : (
                <div className="flex items-center justify-center w-full h-40">
                    <Spinner />
                </div>
            )}
        </article>
    );
};

export default ProductCategoryCard;
