import { API_URL, PLACEHOLDER_IMAGE_ID } from "../../vars";

import PrimaryHeading from "../../components/typography/PrimaryHeading";
import ProductCategoryCard from "../../components/products/ProductCategoryCard";
import Spinner from "../../components/general/Spinner";
import Wrapper from "../../components/layout/Wrapper";
import useFetch from "../../hooks/useFetch";
import { useEffect } from "react";

const Products: React.FunctionComponent = () => {
    const { data } = useFetch<Category[]>(
        `${API_URL}/categories?per_page=15&orderby=id`
    );

    useEffect(() => {
        document.title = "PJP Plant Hire - Products";
    }, []);

    return (
        <Wrapper container>
            <div className="px-4 my-5 md:px-10">
                <PrimaryHeading>Product Range</PrimaryHeading>
            </div>

            <div className="flex flex-wrap md:px-10">
                {data ? (
                    data.map((category) => {
                        if (category.name === "News") return null;

                        return (
                            <ProductCategoryCard
                                categoryName={category.name}
                                categoryUrl={category.link}
                                imageId={
                                    category.acf.featured_image ||
                                    PLACEHOLDER_IMAGE_ID
                                }
                                key={category.id}
                            />
                        );
                    })
                ) : (
                    <Spinner />
                )}
            </div>
        </Wrapper>
    );
};

export default Products;
