import BSCLogo from "../../../assets/images/british-safety-council-logo.png";
import CPALogo from "../../../assets/images/cpa-logo.png";
import Facebook from "../../icons/Facebook";
import Linkedin from "../../icons/Linkedin";
import MastercardLogo from "../../../assets/images/mastercard-logo.png";
import Twitter from "../../icons/Twitter";
import VisaLogo from "../../../assets/images/visa-logo.png";

const Footer: React.FunctionComponent = () => {
    return (
        <footer className="px-4 py-10 lg:px-12">
            <h2 className="mb-5 text-3xl font-bold uppercase font-display text-pjp">
                <a href="/">PJP Plant Hire</a>
            </h2>

            <ul className="mb-10 text-xl font-bold uppercase font-body">
                <li className="mb-3">
                    <a href="/products">Products</a>
                </li>
                <li className="mb-3">
                    <a href="/news">News</a>
                </li>
                <li className="mb-3">
                    <a href="/about">About</a>
                </li>
                {/* <li className="mb-3">
                    <a href="/the-team">The Team</a>
                </li> */}
                <li className="mb-3">
                    <a href="/downloads">Downloads</a>
                </li>
                <li>
                    <a href="/contact">Contact</a>
                </li>
            </ul>

            <ul className="flex justify-center mb-10 lg:justify-start">
                <li className="mr-5">
                    <a href="https://www.facebook.com/pjpuk/">
                        <Facebook />
                    </a>
                </li>

                <li className="mr-5">
                    <a href="https://twitter.com/pjphire">
                        <Twitter />
                    </a>
                </li>

                <li>
                    <a href="https://uk.linkedin.com/in/pjp-plant-hire">
                        <Linkedin />
                    </a>
                </li>
            </ul>

            <div className="flex flex-col lg:flex-row-reverse lg:justify-between lg:items-center">
                <ul className="flex flex-row flex-wrap items-center justify-center mb-10 lg:justify-end lg:w-3/4">
                    <li className="flex items-center justify-center w-1/2 mb-5 lg:w-24 lg:p-1 lg:mb-0 xl:mr-5 xl:w-32">
                        <img
                            src={VisaLogo}
                            alt="VISA logo"
                            className="w-24 xl:w-32"
                        />
                    </li>
                    <li className="flex items-center justify-center w-1/2 mb-5 lg:w-24 lg:px-3 lg:mb-0 xl:mr-5">
                        <img
                            src={MastercardLogo}
                            alt="Mastercard logo"
                            className="w-24"
                        />
                    </li>
                    <li className="flex items-center justify-center w-1/2 mb-5 lg:w-28 lg:px-2 lg:mb-0 xl:mr-5 xl:w-32">
                        <img
                            src={CPALogo}
                            alt="CPA logo"
                            className="w-28 xl:w-32"
                        />
                    </li>
                    <li className="flex items-center justify-center w-1/2 mb-5 lg:w-32 lg:mb-0 xl:mr-5 xl:w-40">
                        <img
                            src={BSCLogo}
                            alt="British Safety Council logo"
                            className="w-32 xl:w-40"
                        />
                    </li>
                </ul>

                <p className="font-body text-md">
                    PJP (UK) Limited, Mill Street, Radcliffe, Manchester, M26
                    1AL
                    <br></br>
                    Registered in England No: 1709275
                    <br></br>
                    <br></br>
                    &copy;{new Date().getFullYear()}, PJP (UK) Limited. All
                    Rights Reserved
                </p>
            </div>
        </footer>
    );
};

export default Footer;
