interface TertiaryHeadingProps {
    alternate?: boolean;
    children: string | React.ReactNode;
}

const TertiaryHeading: React.FunctionComponent<TertiaryHeadingProps> = ({
    alternate = false,
    children,
}) => {
    return (
        <h3
            className={`text-xl font-bold uppercase font-display leading-none transition-all ${
                alternate ? "text-black" : "text-pjp"
            }`}
        >
            {children}
        </h3>
    );
};

export default TertiaryHeading;
