import { API_URL } from "../../../vars";
import Spinner from "../../general/Spinner";
import TertiaryHeading from "../../typography/TertiaryHeading";
import useFetch from "../../../hooks/useFetch";

interface ProductCardProps {
    imageId: number;
    productCategory?: string;
    productMetric?: string;
    productName: string;
    productUrl: string;
    productVariation?: string;
}

const ProductCard: React.FunctionComponent<ProductCardProps> = ({
    imageId,
    productCategory,
    productMetric,
    productName,
    productUrl,
    productVariation,
}) => {
    const image = useFetch<any>(`${API_URL}/media/${imageId.toString()}`);

    return (
        <article className="w-1/2 mb-10 lg:w-1/4">
            {image.data ? (
                <a
                    href={productUrl}
                    className="block p-5 mx-2 transition-all duration-300 bg-white shadow-lg hover:shadow-xl"
                >
                    <img
                        src={image.data.source_url}
                        alt={productName}
                        className="mb-2"
                    />

                    <TertiaryHeading>{productName}</TertiaryHeading>

                    {(productCategory || productVariation) && (
                        <h4 className="mt-1 font-bold text-md font-display">
                            {productCategory}
                            {productCategory && productVariation && " - "}
                            {productVariation}
                        </h4>
                    )}

                    {productMetric && (
                        <p className="text-md font-body">{productMetric}</p>
                    )}
                </a>
            ) : (
                <Spinner />
            )}
        </article>
    );
};

export default ProductCard;
