import { API_URL } from "../../../vars";
import Download from "../../icons/Download";
import { Interweave } from "interweave";
import Spinner from "../../general/Spinner";
import useFetch from "../../../hooks/useFetch";
import { useState } from "react";

interface ResourceProps {
    resourceId: number;
}

const Resource: React.FunctionComponent<ResourceProps> = ({ resourceId }) => {
    const [active, setActive] = useState<boolean>(false);

    const file = useFetch<any>(`${API_URL}/media/${resourceId.toString()}`);

    return (
        <article className="w-1/2 pr-5 mb-5 lg:w-1/4">
            {file && file.data ? (
                <>
                    <a
                        href={file.data.source_url}
                        target="_blank"
                        rel="noreferrer"
                        className={`inline-flex items-center justify-center p-5 mb-2 rounded-full transition ${
                            active ? "bg-alt" : "bg-pjp"
                        }`}
                        onMouseEnter={() => setActive(true)}
                        onMouseLeave={() => setActive(false)}
                    >
                        <Download />
                    </a>

                    <a
                        href={file.data.source_url}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <h4
                            className={`text-lg font-bold font-display transition ${
                                active ? "text-alt" : "text-pjp"
                            }`}
                            onMouseEnter={() => setActive(true)}
                            onMouseLeave={() => setActive(false)}
                        >
                            <Interweave content={file.data.title.rendered} />{" "}
                            (PDF)
                        </h4>
                    </a>
                </>
            ) : (
                <Spinner />
            )}
        </article>
    );
};

export default Resource;
