import { API_URL } from "../../vars";
import PrimaryHeading from "../../components/typography/PrimaryHeading";
import Resource from "../../components/resources/Resource";
import Spinner from "../../components/general/Spinner";
import Wrapper from "../../components/layout/Wrapper";
import { useEffect } from "react";
import useFetch from "../../hooks/useFetch";

const Resources: React.FunctionComponent = () => {
    const { data } = useFetch<any>(`${API_URL}/pages?slug=downloads`);

    useEffect(() => {
        document.title = "PJP Plant Hire - Downloads";
    }, []);

    return (
        <Wrapper container>
            <div className="px-4 mt-5 lg:px-10">
                <div className="mb-5">
                    <PrimaryHeading>Downloads</PrimaryHeading>
                </div>

                <div className="flex flex-row flex-wrap -mr-5">
                    {data && data[0] ? (
                        data[0].acf.resources.map(
                            (item: { resource: number }) => {
                                return (
                                    <Resource
                                        key={item.resource}
                                        resourceId={item.resource}
                                    />
                                );
                            }
                        )
                    ) : (
                        <Spinner />
                    )}
                </div>
            </div>
        </Wrapper>
    );
};

export default Resources;
