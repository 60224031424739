import Button from "../../components/buttons/Button";
import { MEDIA_URL } from "../../vars";
import PrimaryHeading from "../../components/typography/PrimaryHeading";
import Wrapper from "../../components/layout/Wrapper";
import { useEffect } from "react";

const About: React.FunctionComponent = () => {
    useEffect(() => {
        document.title = "PJP Plant Hire - About PJP";
    }, []);

    return (
        <Wrapper container>
            <div className="px-4 mx-auto my-5 lg:px-10 lg:max-w-4xl">
                <PrimaryHeading>About PJP</PrimaryHeading>
            </div>

            <article className="px-4 lg:flex lg:flex-col lg:items-center lg:justify-center lg:px-10">
                <img
                    src={`${MEDIA_URL}/uploads/2022/12/about-1.jpeg`}
                    alt="PJP delivering"
                    className="mb-5 lg:mb-10"
                />

                <p className="mb-5 text-xl lg:px-10 lg:max-w-4xl lg:mb-10 font-body">
                    PJP for the last 40 years has continually demonstrated a
                    high degree of expertise, experience and professionally
                    accredited processes to provide lower cost rental solutions
                    within the plant hire industry.
                    <br></br>
                    <br></br>
                    As a company with a firm foundation in the North West we
                    have an excellent strategic base to support our operational
                    capabilities across the UK.
                    <br></br>
                    <br></br>
                    Based on the things we do best and value most, our customers
                    experience an absolute commitment to service excellence from
                    all corners of our organisation.
                    <br></br>
                    <br></br>
                    The size of our rental fleet is very extensive and is
                    constantly being refreshed within a generous replacement
                    programme. We are a company that embrace new technologies
                    and innovative solutions to streamline transactional
                    processes, to make doing business easier.
                </p>

                <img
                    src={`${MEDIA_URL}/uploads/2022/12/about-2.jpeg`}
                    alt="Excavator at work"
                    className="mb-5 lg:mb-10"
                />

                <h3 className="mb-5 text-3xl font-bold uppercase lg:mb-10 font-display text-pjp">
                    About PJP
                </h3>

                <p className="mb-5 text-xl lg:mb-10 font-body lg:px-10 lg:max-w-4xl">
                    We endeavour to engender a commitment to quality, safety and
                    environmental sustainability in every aspect of our
                    business.
                    <br></br>
                    <br></br>
                    Implicit in everything we do is a fierce determination to
                    minimise our impact on the environment and our communities.
                </p>

                <img
                    src={`${MEDIA_URL}/uploads/2022/12/about-4.jpeg`}
                    alt="Placeholder"
                    className="mb-5 lg:mb-10"
                />

                <h3 className="mb-5 text-3xl font-bold uppercase lg:mb-10 font-display text-pjp">
                    Here To Help
                </h3>

                <p className="mb-5 text-xl lg:mb-10 font-body lg:px-10 lg:max-w-4xl">
                    Why not experience the difference? More and more customers
                    are turning to PJP for their plant hire requirements. If you
                    need any assistance in finding the right equipment, more
                    information about the product range, or if you’re looking
                    for a quote - get in touch with PJP.
                </p>

                <Button linkUrl="/contact">Contact PJP</Button>
            </article>
        </Wrapper>
    );
};

export default About;
