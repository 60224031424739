import { API_URL } from "../../../vars";
import Download from "../../icons/Download";
import Spinner from "../../general/Spinner";
import TertiaryHeading from "../../typography/TertiaryHeading";
import useFetch from "../../../hooks/useFetch";

interface ProductSpecificationItems {
    specification: string;
    value: string;
}
interface ProductSpecificationProps {
    specificationSheetId: number;
    specificationTable: ProductSpecificationItems[];
}

const ProductSpecification: React.FunctionComponent<
    ProductSpecificationProps
> = ({ specificationSheetId, specificationTable }) => {
    const specificationSheet = useFetch<any>(
        `${API_URL}/media/${specificationSheetId.toString()}`
    );

    return (
        <>
            <div className="flex w-full p-4 lg:p-0 lg:inline-flex lg:mb-10">
                <div className="w-full lg:border-r lg:border-gray-800 lg:w-auto">
                    {specificationTable.map((row, i) => {
                        return (
                            <div className="flex justify-between" key={i}>
                                <p
                                    className="block w-2/3 mb-2 mr-2 text-lg font-bold lg:w-full lg:mr-10 font-body"
                                    key={row.specification}
                                >
                                    {row.specification}
                                </p>

                                <p
                                    className="block w-1/3 pb-2 text-lg text-right border-l border-gray-800 font-body lg:hidden"
                                    key={i}
                                >
                                    {row.value}
                                </p>
                            </div>
                        );
                    })}
                </div>

                <div className="hidden pl-10 lg:block">
                    {specificationTable.map((row, i) => {
                        return (
                            <p className="mb-2 text-lg font-body" key={i}>
                                {row.value}
                            </p>
                        );
                    })}
                </div>
            </div>

            {specificationSheet && specificationSheet.data ? (
                <div className="p-4 lg:p-0">
                    <a
                        className="flex items-center justify-center p-4 transition border-2 lg:py-2 lg:px-10 lg:inline-flex rounded-xl border-pjp hover:bg-gray-100"
                        href={specificationSheet.data.source_url}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <span className="inline-flex items-center justify-center p-5 mr-6 transition rounded-full bg-pjp">
                            <Download />
                        </span>

                        <TertiaryHeading>Specification Sheet</TertiaryHeading>
                    </a>
                </div>
            ) : (
                <Spinner />
            )}
        </>
    );
};

export default ProductSpecification;
