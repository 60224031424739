import { BrowserRouter, Route, Routes } from "react-router-dom";

import About from "./pages/About";
import Article from "./pages/Article";
import Contact from "./pages/Contact/Contact";
import Fallback from "./pages/Fallback";
import Footer from "./components/layout/Footer";
import Header from "./components/layout/Header";
import Home from "./pages/Home";
import News from "./pages/News";
import Product from "./pages/Product";
import ProductCategory from "./pages/ProductCategory";
import Products from "./pages/Products";
import Resources from "./pages/Resources";

const App: React.FunctionComponent = () => {
    return (
        <>
            <Header />
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/news/:post" element={<Article />}></Route>
                    <Route path="/contact" element={<Contact />}></Route>
                    <Route path="/news" element={<News />}></Route>
                    <Route
                        path="/products/:category/:product"
                        element={<Product />}
                    ></Route>
                    <Route path="/products" element={<Products />}></Route>
                    <Route
                        path="/products/:category"
                        element={<ProductCategory />}
                    ></Route>
                    <Route path="/downloads" element={<Resources />}></Route>
                    {/* <Route path="/the-team" element={<TheTeam />}></Route> */}
                    <Route path="/*" element={<Fallback />} />
                </Routes>
            </BrowserRouter>
            <Footer />
        </>
    );
};

export default App;
