import { useLocation, useNavigate } from "react-router-dom";

import { API_URL } from "../../vars";
import Accordion from "../../components/general/Accordion";
import { Interweave } from "interweave";
import PrimaryHeading from "../../components/typography/PrimaryHeading";
import ProductCards from "../../components/products/ProductCards";
import ProductSpecification from "../../components/products/ProductSpecification";
import ProductSummary from "../../components/products/ProductSummary";
import SecondaryHeading from "../../components/typography/SecondaryHeading";
import Spinner from "../../components/general/Spinner";
import Wrapper from "../../components/layout/Wrapper";
import getFinalURLPartFromPathname from "../../utils/getFinalURLPartFromPathname";
import useFetch from "../../hooks/useFetch";
import { useEffect } from "react";

interface BreadcrumbsProps {
    productCategoryId: number;
    productName: string;
}

interface ProductImageProps {
    description: string;
    imageId: number;
}

interface ProductInformationProps {
    productCategoryId?: number;
    productType?: string;
}

const Breadcrumbs: React.FunctionComponent<BreadcrumbsProps> = ({
    productCategoryId,
    productName,
}) => {
    const { data } = useFetch<any>(
        `${API_URL}/categories/${productCategoryId}`
    );

    return (
        data && (
            <div className="px-4 my-5 lg:px-10">
                <a
                    href="/products"
                    className="mr-2 text-sm underline font-body text-pjp"
                >
                    Products
                </a>

                <span className="mr-2 text-sm font-body text-pjp">&gt;</span>

                <a
                    href={data.link}
                    className="mr-2 text-sm underline font-body text-pjp"
                >
                    <Interweave content={data.name} />
                </a>

                <span className="mr-2 text-sm font-body text-pjp">&gt;</span>

                <span className="text-sm font-bold font-body text-pjp">
                    <Interweave content={productName} />
                </span>
            </div>
        )
    );
};

const ProductImage: React.FunctionComponent<ProductImageProps> = ({
    description,
    imageId,
}) => {
    const image = useFetch<any>(`${API_URL}/media/${imageId.toString()}`);

    return image && image.data ? (
        <img src={image.data.source_url} alt={description} />
    ) : (
        <Spinner />
    );
};

const ProductInformation: React.FunctionComponent<ProductInformationProps> = ({
    productCategoryId,
    productType,
}) => {
    const { data } = useFetch<any>(
        `${API_URL}/categories/${productCategoryId}`
    );

    return (
        data && (
            <h4 className="text-2xl font-bold uppercase font-display">
                <Interweave
                    content={`${data.name} ${
                        productType && `- ${productType}`
                    }`}
                />
            </h4>
        )
    );
};

const Product: React.FunctionComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const productSlug = getFinalURLPartFromPathname(location.pathname);

    const { data } = useFetch<any>(`${API_URL}/products?slug=${productSlug}`);

    useEffect(() => {
        if (data) document.title = `PJP Plant Hire - ${data[0].title.rendered}`;
    }, [data]);

    const getAttachmentIds = (attachments: { attachment: number }[]) => {
        return attachments.reduce(
            (acc: any, attachment: { attachment: number }) => {
                return [...acc, attachment.attachment];
            },
            []
        );
    };

    const getRelatedProductIds = (products: { product: number }[]) => {
        return products.reduce((acc: any, product: { product: number }) => {
            return [...acc, product.product];
        }, []);
    };

    if (data && data.length === 0) {
        navigate("/404");
    }

    return (
        <Wrapper container>
            {data ? (
                <>
                    <Breadcrumbs
                        productCategoryId={data[0].categories[0]}
                        productName={data[0].title.rendered}
                    />

                    <div className="lg:flex lg:flex-row-reverse">
                        <div className="px-4 my-5 lg:m-0 lg:px-10 lg:flex-shrink-0 lg:max-w-1/2">
                            <ProductImage
                                description={data[0].title.rendered}
                                imageId={data[0].acf.feature_image}
                            />
                        </div>

                        <div className="px-4 my-5 lg:flex-1 lg:mt-0 lg:mb-5 lg:px-10">
                            <div className="mb-5">
                                <PrimaryHeading>
                                    {data[0].title.rendered}
                                </PrimaryHeading>
                            </div>

                            <ProductInformation
                                productCategoryId={data[0].categories[0]}
                                productType={data[0].acf.product_type}
                            />

                            <p className="text-2xl uppercase font-body lg:mb-5">
                                {data[0].acf.metric_value &&
                                    `${data[0].acf.metric_value} ${data[0].acf.metric}`}
                            </p>

                            <div className="hidden lg:block">
                                <ProductSummary
                                    content={data[0].acf.description}
                                />

                                {data[0].acf.specification_sheet && (
                                    <>
                                        <div className="mb-5">
                                            <SecondaryHeading>
                                                Specification
                                            </SecondaryHeading>
                                        </div>

                                        <ProductSpecification
                                            specificationSheetId={
                                                data[0].acf.specification_sheet
                                            }
                                            specificationTable={
                                                data[0].acf.specification_table
                                            }
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="my-5 lg:hidden">
                        <Accordion
                            attachments={
                                data[0].acf.attachments && (
                                    <ProductCards
                                        productIds={getAttachmentIds(
                                            data[0].acf.attachments
                                        )}
                                    />
                                )
                            }
                            specification={
                                data[0].acf.specification_sheet && (
                                    <ProductSpecification
                                        specificationSheetId={
                                            data[0].acf.specification_sheet
                                        }
                                        specificationTable={
                                            data[0].acf.specification_table
                                        }
                                    />
                                )
                            }
                            summary={
                                <ProductSummary
                                    content={data[0].acf.description}
                                />
                            }
                        />
                    </div>

                    {data[0].acf.attachments && (
                        <>
                            <div className="hidden my-5 lg:px-10 lg:block">
                                <SecondaryHeading>Attachments</SecondaryHeading>
                            </div>

                            <div className="hidden lg:block">
                                <ProductCards
                                    productIds={getAttachmentIds(
                                        data[0].acf.attachments
                                    )}
                                />
                            </div>
                        </>
                    )}

                    {data[0].acf.related_products && (
                        <>
                            <div className="px-4 my-5 lg:px-10">
                                <SecondaryHeading>
                                    Related Products
                                </SecondaryHeading>
                            </div>

                            <ProductCards
                                productIds={getRelatedProductIds(
                                    data[0].acf.related_products
                                )}
                            />
                        </>
                    )}
                </>
            ) : (
                <Spinner />
            )}
        </Wrapper>
    );
};

export default Product;
