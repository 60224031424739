interface SecondaryHeadingProps {
    alternate?: boolean;
    children: string;
}

const SecondaryHeading: React.FunctionComponent<SecondaryHeadingProps> = ({
    alternate = false,
    children,
}) => {
    return (
        <h2
            className={`text-3xl font-bold uppercase font-display ${
                alternate ? "text-white" : "text-pjp"
            }`}
        >
            {children}
        </h2>
    );
};

export default SecondaryHeading;
