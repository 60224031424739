import { API_URL } from "../../../vars";
import Button from "../../buttons/Button";
import { Markup } from "interweave";
import PrimaryHeading from "../../typography/PrimaryHeading";
import SecondaryHeading from "../../typography/SecondaryHeading";
import Spinner from "../Spinner";
import useFetch from "../../../hooks/useFetch";

interface ImageContentBlockProps {
    alternate?: boolean;
    content: string;
    heading: string;
    imageId: number;
    linkId: number;
    linkText: string;
    primary?: boolean;
}

const ImageContentBlock: React.FunctionComponent<ImageContentBlockProps> = ({
    alternate = false,
    content,
    heading,
    imageId,
    linkText,
    linkId,
    primary = false,
}) => {
    const image = useFetch<any>(`${API_URL}/media/${imageId.toString()}`);

    const link = useFetch<any>(`${API_URL}/pages/${linkId.toString()}`);

    return (
        <section
            className={`flex flex-col  ${
                alternate ? "lg:flex-row" : "lg:flex-row-reverse"
            }`}
        >
            {image && image.data ? (
                <div className="flex items-center justify-center w-full overflow-hidden lg:w-1/2">
                    <img
                        src={image.data.source_url}
                        alt={image.data.alt_text}
                        className="object-cover h-full"
                    />
                </div>
            ) : (
                <div className="flex items-center justify-center w-full p-10 lg:w-1/2">
                    <Spinner />
                </div>
            )}

            <div
                className={`flex flex-col justify-center px-4 py-6 lg:px-10 lg:py-16 lg:w-1/2 ${
                    alternate ? "bg-alt" : ""
                }`}
            >
                <div className="mb-5">
                    {primary ? (
                        <PrimaryHeading alternate={alternate}>
                            {heading}
                        </PrimaryHeading>
                    ) : (
                        <SecondaryHeading alternate={alternate}>
                            {heading}
                        </SecondaryHeading>
                    )}
                </div>

                <p
                    className={`mb-5 text-xl font-body ${
                        alternate ? "text-white" : ""
                    }`}
                >
                    <Markup content={content} />
                </p>

                {link && link.data ? (
                    <Button linkUrl={link.data.link}>{linkText}</Button>
                ) : (
                    <Spinner></Spinner>
                )}
            </div>
        </section>
    );
};

export default ImageContentBlock;
