import { API_URL } from "../../vars";
import NewsFeature from "../../components/news/NewsFeature";
import { PLACEHOLDER_IMAGE_ID } from "../../vars";
import PrimaryHeading from "../../components/typography/PrimaryHeading";
import Spinner from "../../components/general/Spinner";
import Wrapper from "../../components/layout/Wrapper";
import { useEffect } from "react";
import useFetch from "../../hooks/useFetch";

const News: React.FunctionComponent = () => {
    const { data } = useFetch<any>(`${API_URL}/posts?per_page=12`);

    useEffect(() => {
        document.title = "PJP Plant Hire - News";
    }, []);

    return (
        <Wrapper container>
            <div className="px-4 my-5 md:px-10">
                <PrimaryHeading>Latest News</PrimaryHeading>
            </div>

            <div className="px-4 md:-mr-5 md:px-10 md:flex md:flex-wrap">
                {data ? (
                    data.map((post: any) => {
                        return (
                            <NewsFeature
                                key={post.id}
                                articleUrl={post.link}
                                date={post.date}
                                heading={post.title.rendered}
                                imageId={
                                    post.featured_media > 0
                                        ? post.featured_media
                                        : PLACEHOLDER_IMAGE_ID
                                }
                            />
                        );
                    })
                ) : (
                    <Spinner />
                )}
            </div>
        </Wrapper>
    );
};

export default News;
