import Email from "../../icons/Email";
import Phone from "../../icons/Phone";
import logo from "../../../assets/images/pjp-logo.jpg";
import { useState } from "react";

const Header: React.FunctionComponent = () => {
    const [navigationOpen, setNavigationOpen] = useState<boolean>(false);

    return (
        <header className="fixed z-10 w-full bg-white shadow shadow-gray-500">
            <div className="flex items-center justify-between">
                <div className="pl-4 pr-2 lg:pl-10">
                    <a href="/">
                        <img
                            src={logo}
                            alt="PJP Logo"
                            className="w-32 lg:w-48 py-2"
                        />
                    </a>
                </div>

                <button
                    onClick={() => setNavigationOpen(!navigationOpen)}
                    className="flex flex-col items-center justify-center px-4 py-5 lg:hidden"
                >
                    <span
                        className="block w-6 h-0.5 mb-1.5 bg-pjp transition-all duration-300"
                        style={{
                            transform: navigationOpen
                                ? "rotate(-45deg) translate(-3px, 3px)"
                                : "none",
                        }}
                    ></span>
                    <span
                        className="block w-6 h-0.5 bg-pjp transition-all duration-300"
                        style={{
                            transform: navigationOpen
                                ? "rotate(45deg) translate(-3px, -3px)"
                                : "none",
                        }}
                    ></span>
                </button>

                <div className="hidden lg:flex lg:items-center">
                    <ul className="flex items-center mr-5">
                        <li className="mr-5 xl:mr-10">
                            <a
                                href="/products"
                                className="block py-4 font-bold uppercase transition font-body text-md text-pjp hover:text-alt"
                            >
                                Products
                            </a>
                        </li>
                        <li className="mr-5 xl:mr-10">
                            <a
                                href="/news"
                                className="block py-4 font-bold uppercase transition font-body text-md text-pjp hover:text-alt"
                            >
                                News
                            </a>
                        </li>
                        <li className="mr-5 xl:mr-10">
                            <a
                                href="/about"
                                className="block py-4 font-bold uppercase transition font-body text-md text-pjp hover:text-alt"
                            >
                                About
                            </a>
                        </li>
                        <li className="mr-5 xl:mr-10">
                            <a
                                href="/downloads"
                                className="block py-4 font-bold uppercase transition font-body text-md text-pjp hover:text-alt"
                            >
                                Downloads
                            </a>
                        </li>
                        <li>
                            <a
                                href="/contact"
                                className="block py-4 font-bold uppercase transition font-body text-md text-pjp hover:text-alt"
                            >
                                Contact
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <nav
                className={`px-4 bg-pjp overflow-hidden transition-all duration-500 flex flex-col lg:hidden ${
                    navigationOpen ? "h-128 py-6" : "h-0"
                }`}
            >
                <ul className="px-2 mb-8 text-xl font-bold text-white uppercase font-body">
                    <li className="mb-3">
                        <a href="/products" className="block">
                            Products
                        </a>
                    </li>
                    <li className="mb-3">
                        <a href="/news" className="block">
                            News
                        </a>
                    </li>
                    <li className="mb-3">
                        <a href="/about" className="block">
                            About
                        </a>
                    </li>
                    {/* <li className="mb-3">
                        <a href="/the-team" className="block">
                            The Team
                        </a>
                    </li> */}
                    <li className="mb-3">
                        <a href="/downloads" className="block">
                            Downloads
                        </a>
                    </li>
                    <li>
                        <a href="/contact" className="block">
                            Contact
                        </a>
                    </li>
                </ul>

                <div className="flex items-center justify-start px-2 mt-14">
                    <a
                        href="tel:01619590000"
                        className="flex items-center justify-center w-16 h-16 p-4 mr-4 bg-white rounded-full"
                    >
                        <Phone dark />
                    </a>
                    <a
                        href="mailto:hire@pjpuk.com"
                        className="flex items-center justify-center w-16 h-16 p-4 bg-white rounded-full"
                    >
                        <Email dark />
                    </a>
                </div>
            </nav>
        </header>
    );
};

export default Header;
