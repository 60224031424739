import { useLocation, useNavigate } from "react-router-dom";

import { API_URL } from "../../vars";
import { Interweave } from "interweave";
import PrimaryHeading from "../../components/typography/PrimaryHeading";
import Spinner from "../../components/general/Spinner";
import Wrapper from "../../components/layout/Wrapper";
import formatDate from "../../utils/formatDate";
import getFinalURLPartFromPathname from "../../utils/getFinalURLPartFromPathname";
import useFetch from "../../hooks/useFetch";
import { useEffect } from "react";
import getPageTitle from "../../utils/getPageTitle";

interface ArticleImageProps {
    description: string;
    imageId: number;
}

const ArticleImage: React.FunctionComponent<ArticleImageProps> = ({
    description,
    imageId,
}) => {
    const image = useFetch<any>(`${API_URL}/media/${imageId.toString()}`);

    return image && image.data ? (
        <img src={image.data.source_url} alt={description} />
    ) : (
        <Spinner />
    );
};

const Article: React.FunctionComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const postSlug = getFinalURLPartFromPathname(location.pathname);

    const { data } = useFetch<any>(`${API_URL}/posts?slug=${postSlug}`);

    useEffect(() => {
        document.title = `PJP Plant Hire - ${getPageTitle(postSlug)}`;
    }, [postSlug]);

    if (data && data.length === 0) {
        navigate("/404");
    }

    return (
        <Wrapper container>
            <div className="px-4 my-5 lg:px-10">
                <a
                    href="/news"
                    className="mr-2 text-sm underline font-body text-pjp"
                >
                    News
                </a>
                <span className="mr-2 text-sm font-body text-pjp">&gt;</span>
                <span className="text-sm font-bold font-body text-pjp">
                    <Interweave content={data && data[0].title.rendered} />
                </span>
            </div>

            {data ? (
                <article className="px-4 lg:px-10">
                    <div className="mb-5">
                        <PrimaryHeading>
                            {data[0].title.rendered}
                        </PrimaryHeading>
                    </div>

                    <div className="mb-5">
                        <ArticleImage
                            description={data[0].title.rendered}
                            imageId={data[0].featured_media}
                        />
                    </div>

                    <p className="mb-5 text-lg font-bold font-body">
                        {formatDate(data[0].date)}
                    </p>

                    <div className="mb-5 text-lg font-body">
                        <Interweave content={data[0].content.rendered} />
                    </div>
                </article>
            ) : (
                <Spinner />
            )}
        </Wrapper>
    );
};

export default Article;
