import Wrapper from "../../components/layout/Wrapper";
import bridge from "../../assets/images/bridge.jpeg";
import { useEffect } from "react";

const Fallback: React.FunctionComponent = () => {
    useEffect(() => {
        document.title = "PJP Plant Hire - Page Not Found";
    }, []);

    return (
        <Wrapper container>
            <div className="p-4 lg:flex lg:flex-row-reverse lg:px-10 lg:py-40">
                <img src={bridge} alt="Vehicle on a bridge" className="mb-5" />

                <div className="lg:mr-5">
                    <h1 className="mb-5 text-4xl font-bold uppercase font-display text-pjp">
                        Error code: 404
                    </h1>

                    <h2 className="mb-5 text-2xl font-bold uppercase font-display text-alt">
                        Sorry, we can't seem to find the page you're looking
                        for.
                    </h2>

                    <a
                        href="/"
                        className="text-lg underline font-body text-pjp"
                    >
                        Return home
                    </a>
                </div>
            </div>
        </Wrapper>
    );
};

export default Fallback;
