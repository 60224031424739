import { Interweave } from "interweave";

interface ProductSummaryProps {
    content: string;
}

const ProductSummary: React.FunctionComponent<ProductSummaryProps> = ({
    content,
}) => {
    return (
        <div className="p-4 lg:p-0 lg:mb-10">
            <Interweave content={content} />
        </div>
    );
};

export default ProductSummary;
