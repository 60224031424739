import { API_URL } from "../../../vars";
import ProductCard from "../ProductCard/ProductCard";
import Spinner from "../../general/Spinner";
import useFetch from "../../../hooks/useFetch";

interface CardProps {
    productId: number;
}

interface ProductCardsProps {
    productIds: number[];
}

const Card: React.FunctionComponent<CardProps> = ({ productId }) => {
    const { data } = useFetch<any>(`${API_URL}/products/${productId}`);

    return data ? (
        <ProductCard
            imageId={data.acf.thumbnail_image}
            productName={data.title.rendered}
            productUrl={data.link}
            productVariation={data.acf.product_type}
        />
    ) : (
        <div className="inline-block w-1/2 lg:w-1/4">
            <Spinner />
        </div>
    );
};

const ProductCards: React.FunctionComponent<ProductCardsProps> = ({
    productIds,
}) => {
    return (
        <div className="flex flex-wrap md:px-10 md:py-0">
            {productIds.map((product, i) => {
                return <Card key={i} productId={product} />;
            })}
        </div>
    );
};

export default ProductCards;
