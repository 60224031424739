import "./Spinner.css";

const Spinner: React.FunctionComponent = () => {
    return (
        <div className="flex items-center justify-center w-full">
            <div className="inline-block w-20 h-20 border-4 rounded-full spinner border-pjp border-t-alt"></div>
        </div>
    );
};

export default Spinner;
