import Clock from "../../components/icons/Clock";
import Email from "../../components/icons/Email";
import Location from "../../components/icons/Location";
import Phone from "../../components/icons/Phone";
import PrimaryHeading from "../../components/typography/PrimaryHeading";
import TertiaryHeading from "../../components/typography/TertiaryHeading";
import Wrapper from "../../components/layout/Wrapper";
import { useEffect } from "react";

const Contact: React.FunctionComponent = () => {
    useEffect(() => {
        document.title = "PJP Plant Hire - Contact PJP";
    }, []);

    return (
        <Wrapper container>
            <div className="px-4 my-5 lg:px-10">
                <PrimaryHeading>Contact PJP</PrimaryHeading>
            </div>
            <div className="lg:flex">
                <div className="mb-5 lg:w-2/3">
                    <iframe
                        className="h-64 lg:h-128 lg:px-10"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2370.0547961938873!2d-2.3276898837984685!3d53.55678928002362!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487ba59de0b2ee4f%3A0xfb5254eb9cad2afb!2sPJP%20PLANT%20HIRE!5e0!3m2!1sen!2suk!4v1669143561800!5m2!1sen!2suk"
                        width="100%"
                        style={{ border: 0 }}
                        allowFullScreen={false}
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        title="PJP Map"
                    ></iframe>
                </div>

                <div className="px-4 lg:px-10">
                    <div className="mb-5">
                        <div className="flex items-center mb-2">
                            <div className="flex items-center justify-center w-16 h-16 p-4 mr-2 rounded-full bg-pjp">
                                <Location />
                            </div>

                            <TertiaryHeading>Address</TertiaryHeading>
                        </div>
                        <p className="font-body text-md">
                            PJP (UK) Limited
                            <br></br>
                            Mill Street
                            <br></br>
                            Radcliffe
                            <br></br>
                            Manchester
                            <br></br>
                            M26 1AL
                            <br></br>
                            <br></br>
                        </p>
                        <a
                            href="https://goo.gl/maps/Pg7yhTsW5AmAGuHr8"
                            className="underline text-pjp font-body text-md"
                        >
                            Get directions &gt;
                        </a>
                    </div>

                    <div className="mb-5">
                        <div className="flex items-center mb-2">
                            <div className="flex items-center justify-center w-16 h-16 p-4 mr-2 rounded-full bg-pjp">
                                <Phone />
                            </div>

                            <TertiaryHeading>Telephone</TertiaryHeading>
                        </div>

                        <a
                            href="tel:01619590000"
                            className="underline text-pjp font-body text-md"
                        >
                            0161 959 0000
                        </a>
                    </div>

                    <div className="mb-5">
                        <div className="flex items-center mb-2">
                            <div className="flex items-center justify-center w-16 h-16 p-4 mr-2 rounded-full bg-pjp">
                                <Email />
                            </div>

                            <TertiaryHeading>Email</TertiaryHeading>
                        </div>

                        <a
                            href="mailto:hire@pjpuk.com"
                            className="underline text-pjp font-body text-md"
                        >
                            hire@pjpuk.com
                        </a>
                    </div>

                    <div className="mb-5">
                        <div className="flex items-center mb-2">
                            <div className="flex items-center justify-center w-16 h-16 p-4 mr-2 rounded-full bg-pjp">
                                <Clock />
                            </div>

                            <TertiaryHeading>Opening Hours</TertiaryHeading>
                        </div>
                        <p className="font-body text-md">
                            Monday - Friday
                            <br></br>
                            7:00am - 6:00pm
                        </p>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

export default Contact;
