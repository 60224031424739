import { useLocation, useNavigate } from "react-router-dom";

import { API_URL } from "../../vars";
import PrimaryHeading from "../../components/typography/PrimaryHeading";
import ProductCard from "../../components/products/ProductCard";
import Wrapper from "../../components/layout/Wrapper";
import capitaliseFirstLetter from "../../utils/capitaliseFirstLetter";
import getFinalURLPartFromPathname from "../../utils/getFinalURLPartFromPathname";
import useFetch from "../../hooks/useFetch";
import { Interweave } from "interweave";
import { useEffect } from "react";
import getPageTitle from "../../utils/getPageTitle";

interface ProductsByCategoryProps {
    category: string;
    id: number;
}

const ProductsByCategory: React.FunctionComponent<ProductsByCategoryProps> = ({
    id,
}) => {
    const { data } = useFetch<any>(
        `${API_URL}/products?categories=${id}&per_page=100&order=asc`
    );

    return (
        <>
            {data && (
                <div className="flex flex-wrap md:px-10">
                    {data.map((product: Product) => {
                        return (
                            <ProductCard
                                key={product.slug}
                                imageId={product.acf.thumbnail_image}
                                productMetric={
                                    product.acf.metric_value
                                        ? `${product.acf.metric_value} ${product.acf.metric}`
                                        : ""
                                }
                                productName={product.title.rendered}
                                productUrl={product.link}
                                productVariation={product.acf.product_type}
                            />
                        );
                    })}
                </div>
            )}
        </>
    );
};

const ProductCategory: React.FunctionComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const categoryName = getFinalURLPartFromPathname(location.pathname);

    const { data } = useFetch<any>(
        `${API_URL}/categories?slug=${categoryName}`
    );

    useEffect(() => {
        document.title = `PJP Plant Hire - ${getPageTitle(categoryName)}`;
    }, [categoryName]);

    if (data && data.length === 0) {
        navigate("/404");
    }

    return (
        <Wrapper container>
            <div className="px-4 my-5 md:px-10">
                <a
                    href="/products"
                    className="mr-2 text-sm underline font-body text-pjp"
                >
                    Products
                </a>

                <span className="mr-2 text-sm font-body text-pjp">&gt;</span>

                <span className="text-sm font-bold font-body text-pjp">
                    {data && (
                        <Interweave
                            content={capitaliseFirstLetter(data[0].name)}
                        />
                    )}
                </span>
            </div>

            {data && data.length && (
                <>
                    <div className="px-4 my-5 md:px-10">
                        <PrimaryHeading>
                            <Interweave
                                content={capitaliseFirstLetter(data[0].name)}
                            />
                        </PrimaryHeading>
                    </div>

                    <ProductsByCategory
                        category={data[0].slug}
                        id={data[0]["id"]}
                    />
                </>
            )}
        </Wrapper>
    );
};

export default ProductCategory;
