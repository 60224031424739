import AccordionItem from "../AccordionItem";

interface AccordionProps {
    attachments?: React.ReactNode;
    specification?: React.ReactNode;
    summary?: React.ReactNode;
}

const Accordion: React.FunctionComponent<AccordionProps> = ({
    attachments,
    specification,
    summary,
}) => {
    return (
        <section className="border-t border-gray-200">
            {summary && (
                <AccordionItem heading="Summary">{summary}</AccordionItem>
            )}

            {specification && (
                <AccordionItem heading="Specification">
                    {specification}
                </AccordionItem>
            )}

            {attachments && (
                <AccordionItem heading="Attachments">
                    {attachments}
                </AccordionItem>
            )}
        </section>
    );
};

export default Accordion;
