interface LocationProps {
    dark?: boolean;
}

const Location: React.FunctionComponent<LocationProps> = ({ dark = false }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
            className="w-8 h-8"
        >
            <path
                className={dark ? "fill-pjp" : "fill-white"}
                d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 256c-35.3 0-64-28.7-64-64s28.7-64 64-64s64 28.7 64 64s-28.7 64-64 64z"
            />
        </svg>
    );
};

export default Location;
