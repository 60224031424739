import { ReactNode } from "react";

interface WrapperProps {
    children: ReactNode;
    container?: boolean;
}

const Wrapper: React.FunctionComponent<WrapperProps> = ({
    children,
    container = false,
}) => {
    return (
        <main className={`pt-20 ${container && "container mx-auto"}`}>
            {children}
        </main>
    );
};

export default Wrapper;
