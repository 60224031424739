const getFinalURLPartFromPathname = (pathname: string) => {
    const parts = pathname
        .toString()
        .split("/")
        .filter((i) => i);

    return parts[parts.length - 1];
};

export default getFinalURLPartFromPathname;
