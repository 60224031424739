interface ButtonProps {
    children: string;
    linkUrl: string;
}

const Button: React.FunctionComponent<ButtonProps> = ({
    children,
    linkUrl,
}) => {
    return (
        <div>
            <a
                href={linkUrl}
                className="block p-3 font-bold text-center text-white rounded-lg lg:inline-block lg:px-10 bg-pjp"
            >
                {children}
            </a>
        </div>
    );
};

export default Button;
