import { Key, useEffect } from "react";

import { API_URL } from "../../vars";
import Button from "../../components/buttons/Button";
import FeaturedNewsPost from "../../components/news/FeaturedNewsPost";
import ImageContentBlock from "../../components/general/ImageContentBlock";
import SecondaryHeading from "../../components/typography/SecondaryHeading";
import Spinner from "../../components/general/Spinner";
import Wrapper from "../../components/layout/Wrapper";
import useFetch from "../../hooks/useFetch";

const Home: React.FunctionComponent = () => {
    const { data } = useFetch<any>(`${API_URL}/pages?slug=home`);

    useEffect(() => {
        document.title = "PJP Plant Hire - Plant and Equipment Hire";
    }, []);

    return (
        <Wrapper>
            {data ? (
                <>
                    <ImageContentBlock
                        content={data[0].acf.section_1_content}
                        heading={data[0].acf.section_1_heading}
                        imageId={data[0].acf.section_1_image}
                        linkText={data[0].acf.section_1_button_label}
                        linkId={data[0].acf.section_1_button_link}
                        primary
                    />

                    <ImageContentBlock
                        alternate
                        content={data[0].acf.section_2_content}
                        heading={data[0].acf.section_2_heading}
                        imageId={data[0].acf.section_2_image}
                        linkText={data[0].acf.section_2_button_label}
                        linkId={data[0].acf.section_2_button_link}
                        primary
                    />

                    <ImageContentBlock
                        content={data[0].acf.section_3_content}
                        heading={data[0].acf.section_3_heading}
                        imageId={data[0].acf.section_3_image}
                        linkText={data[0].acf.section_3_button_label}
                        linkId={data[0].acf.section_3_button_link}
                        primary
                    />

                    {data[0].acf.featured_posts && (
                        <section className="md:container md:mx-auto md:mt-10">
                            <div className="px-4 py-2 mb-5 text-center lg:px-2">
                                <SecondaryHeading>
                                    Featured News
                                </SecondaryHeading>
                            </div>

                            <div className="flex flex-col mb-5 md:flex-row md:flex-wrap">
                                {data[0].acf.featured_posts.map(
                                    (data: any, i: Key) => (
                                        <FeaturedNewsPost
                                            postId={data.post}
                                            key={i}
                                        />
                                    )
                                )}
                            </div>
                            <div className="p-2 lg:text-center">
                                <Button linkUrl="/news">
                                    View all news stories
                                </Button>
                            </div>
                        </section>
                    )}
                </>
            ) : (
                <div className="p-10">
                    <Spinner />
                </div>
            )}
        </Wrapper>
    );
};

export default Home;
