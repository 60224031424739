import { useRef, useState } from "react";

import Chevron from "../../icons/Chevron";

interface AccordionItemProps {
    children: React.ReactNode;
    heading: string;
}

const AccordionItem: React.FunctionComponent<AccordionItemProps> = ({
    children,
    heading,
}) => {
    const [active, setActive] = useState<boolean>(false);
    const content = useRef<HTMLParagraphElement>(null);

    return (
        <>
            <article className="overflow-hidden border-b border-gray-200">
                <button
                    onClick={() => setActive(!active)}
                    className="flex items-center justify-between w-full p-4"
                >
                    <h4 className="mt-1 text-2xl font-bold uppercase font-display">
                        {heading}
                    </h4>

                    <Chevron rotate={!active} />
                </button>

                <div
                    ref={content}
                    className="overflow-hidden transition-all duration-500"
                    style={{
                        height:
                            active && content && content.current
                                ? `${content.current.scrollHeight}px`
                                : "0px",
                    }}
                >
                    <>{children}</>
                </div>
            </article>
        </>
    );
};

export default AccordionItem;
