import { API_URL } from "../../../vars";
import NewsFeature from "../NewsFeature";
import Spinner from "../../general/Spinner";
import useFetch from "../../../hooks/useFetch";

interface FeaturedNewsPostProps {
    postId: number;
}

const FeaturedNewsPost: React.FunctionComponent<FeaturedNewsPostProps> = ({
    postId,
}) => {
    const { data } = useFetch<any>(`${API_URL}/posts/${postId}`);

    return data ? (
        <NewsFeature
            key={data.id}
            articleUrl={data.link}
            date={data.date}
            heading={data.title.rendered}
            imageId={data.featured_media}
        />
    ) : (
        <div className="mb-10 md:w-1/2 md:mb-0 lg:w-1/4 md:py-10">
            <Spinner />
        </div>
    );
};

export default FeaturedNewsPost;
