import { API_URL } from "../../../vars";
import Spinner from "../../general/Spinner";
import formatDate from "../../../utils/formatDate";
import useFetch from "../../../hooks/useFetch";
interface NewsFeatureProps {
    articleUrl: string;
    date: string;
    heading: string;
    imageId: number;
}

const NewsFeature: React.FunctionComponent<NewsFeatureProps> = ({
    articleUrl,
    date,
    heading,
    imageId,
}) => {
    const image = useFetch<any>(`${API_URL}/media/${imageId.toString()}`);

    return (
        <article className="mb-5 md:w-1/2 lg:w-1/4 md:pr-5">
            <a
                href={articleUrl}
                className="block pb-5 mx-2 transition-all duration-300 bg-white shadow-lg lg:pb-0 hover:shadow-xl"
            >
                {image && image.data ? (
                    <div className="flex items-center justify-center w-full overflow-hidden">
                        <img
                            src={image.data.source_url}
                            alt={image.data.alt_text}
                            className="w-full"
                        />
                    </div>
                ) : (
                    <div className="flex items-center justify-center w-full p-10 ">
                        <Spinner />
                    </div>
                )}

                <div className="p-2 lg:mt-auto">
                    <h4 className="text-xl font-bold font-display">
                        {heading}
                    </h4>
                    <p className="text-lg font-body">{formatDate(date)}</p>
                </div>
            </a>
        </article>
    );
};

export default NewsFeature;
